@import "config";

.results-screen {

  .cardsWrapper {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    position: relative;
    z-index: 1;
    //overflow: auto;
  }

  .card {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.10);
    border-radius: 8px;
    background: white;
    padding: 10px 14px;
    width: calc(50% - 50px);
    transition: all 0.2s 0s;
    border: 2px solid transparent;
    img {
      width: 100%;
      height: auto;
    }

    position: absolute;
    top: 0;

    &:hover {
      box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.20);
      //transform: scale(1.03);
      transition: all 0.3s 0s;
      border: 2px solid $color-orange;
    }

    &.dummy {
      position: relative;
      pointer-events: none;
      opacity: 0;
    }
  }


  .cardleft {
    position: absolute;
    left: 25%;
    transform: translateX(-50%);
  }

  .cardright {
    position: absolute;
    right: 25%;
    transform: translateX(50%);
  }

  .content {
    position: relative;
  }

  .extra {
    position: absolute;
    left: 0;
    bottom: 0;

    width: auto !important
  }




  .selectionComplete {

    .card {
      transition: all 0.5s 0.6s, opacity 0.6s 0.1s, transform 0.5s 0.6s ease-in-out, border 0.3s 0s, box-shadow 0.3s 0s;
      border: 2px solid transparent !important;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.10);
      pointer-events: none;
    }

    .cardleft {
      left: 50%;
    }

    .cardright {
      right: 50%;
    }

    .chosen {
      opacity: 1;
    }
    .unchosen {
      opacity: 0;
    }
  }

  .backbutton {
    position: absolute;
    left: 20px;
    top: 20px;

    button {
      display: flex;

      span {
        margin-left: 10px;
      }
    }
  }


  .titleHolder {
    position: relative;
    overflow: hidden;
    flex-shrink: 0;

    h2 {
      margin-bottom: 40px;

      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }

    .resulting {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(100%);
      opacity: 0;
    }

    &.showing-results {
      .resulting {
        opacity: 1;
        transform: translateY(0%);
        transition: all 0.7s 2.5s ease-out;
      }
      .initial {
        opacity: 0;
        transform: translateY(100%);
        transition: all 0.25s 1.2s ease-in;
      }
    }

  }

  .results-footer {
    margin-top: 15px;
    text-align: center;

    opacity: 0;
    transition: all 0.5s;
    transform: translateY(-50%);
    max-height: 0;
    overflow: hidden;

    &.showing-results {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.7s 2.5s ease-out, max-height 0s;
      display: block;
      max-height: 2000px;

      p {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .caption {
      @extend p;
      font-size: 12px;
      color: #544D44;
      letter-spacing: 0;
      text-align: center;
      line-height: (16/12);
      margin-bottom: 20px;
    }
  }



  @media (max-width: 767px) {
    justify-content: flex-start;

    .titleHolder {
      display: block;
      height: auto;
      overflow: auto;
      position: relative;
      .resulting {
        position: relative;
      }
      flex-shrink: 0;

      padding-bottom: 20px;

      h2 {
        margin-bottom: 0;
      }

      .initial {
        opacity: 1;
        transform: none !important;
      }
      .resulting {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        opacity: 0;
      }

      //.initial, .resulting {
      //  transition: all 0.5s;
      //  translate: 0 !important;
      //}
      //
      //.initial {
      //  max-height: 200px;
      //}
      //.resulting {
      //  max-height: 0;
      //  opacity: 0;
      //}

    }

    .cardsWrapper {
      display: flex;
      flex-direction: column;
      //background: red;

    }

    .card.dummy {
      display: none;
    }

    .card {
      width: 500px;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;

      &.dummy {
        margin-top: 0 !important
      }

      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .cardleft, .cardright {
      position: relative;
      transform: none;
      left: inherit;
      right: inherit;
      max-height: 450px;

      overflow: hidden;
    }

    .showing-results {


      .initial {
        opacity: 0;
      }
      .resulting {
        opacity: 1;
        transition: all 1s;
        transform: translateY(-50%) !important;
      }
    }

    .selectionComplete {
      .cardleft, .cardright {
        left: inherit;
        right: inherit;
        transition: all 1s ease-in-out;
        margin-bottom: 0;
      }

      .unchosen {
        max-height: 0;
        opacity: 0;
        padding: 0;
        border: 0 !important;
        transform: scale(0.9);
        transition: all 0.5s, padding 0.3s 0.2s;
      }
    }

  }

}

@import "../scss/config";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //height: 100vh;
  ////width: 100vw;
  //text-align: center;
  ////max-width: 750px;
  //margin-left: auto;
  //margin-right: auto;
  text-align: center;

  p {
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.logo {
  margin-bottom: 48px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  @media (max-width: 500px) {
    margin-bottom: 15px;
  }
}
.presents {
  color: $color-orange;
  font-size: 15px;
  letter-spacing: 4.1px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 13px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
}

.loading {
  margin-bottom: 30px;
  transition: all 1.5s 0.2s ease-in;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  svg {
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

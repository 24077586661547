@import "../scss/config";

.button {

  background: #FFFFFF;
  color: $color-text;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.10);
  border-radius: 8px;
  transition: all 0.2s ease;
  border: 3px solid transparent;
  position: relative;

  display: flex;
  flex: 1;

  &:hover {
    background: darken(white, 5%);
    //transform: scale(1.03)
  }

  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }

  &:before {
    content: '';
    width: 100%;
    padding-top: 100%;
  }

  @media (max-width: 700px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    //flex-shrink: 1;
    //margin-right: 0;
    //flex-basis: 150px;
    margin: 0;
    margin-bottom: 15px;

    &:before {
      height: 70px;
      padding-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
      margin-left: 0;
    }
  }
}

.btnContent {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    padding: 10px;
    flex-direction: row;
    justify-content: space-around;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

}


.icon {
  display: block;
  margin-bottom: 15%;
  svg {
    width: 100%;
    height: auto;
  }

  @media (max-width: 700px) {
    width: 50px;
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.selected {
  //background: red;
  border: 3px solid #E84A32;
  background: #FFFFFF !important;
  cursor: default;
  //transform: scale(1.05);
  transition: all 0.3s 0s ease-in-out;

  > .title {
    color: #E84A32;
  }
}


.inactive {
  //opacity: 0;
  transition: all 0.2s 0s;
  //pointer-events: none;
}

.title {
  //margin-top: 56px;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: (22/20);
  @media (max-width: 700px) {
    font-size: 18px;
  }

  @media (max-width: 700px) {
    text-align: left;
    flex: 2;
  }
}

.buttonsWrap {
  //border: 1px solid orange;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 700px) {
    flex-direction: column;
    //width: 180px;
    flex-wrap: wrap;
  }
}

.buttonsWithNavigationBlock {
  //border: 1px solid red;
  position:relative;
  width: 100%;

  > button {
    transition: all 0.15s ease-out;
    display: flex;

    > svg {
      transition: all 0.15s ease-out;
      g {
        transition: all 0.15s ease-out;
        stroke: $color-text;
      }
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }

    &:first-child {
      position: absolute;
      left: 50px;
      top: 50%;
    }
    &:last-child {
      position: absolute;
      right: 50px;
      top: 50%;
    }

    &[disabled] {
      //opacity: 0.5;
      pointer-events: none;
      svg {
        opacity: 0.3;
      }
    }

    &:hover {
      color: $color-orange;
      > svg {
        &:first-child {
          transform: translateX(-3px);
        }
        &:last-child {
          transform: translateX(3px);
        }
        g {
          stroke: $color-orange;
        }
      }
    }
  }
}

@import "scss-reset/_reset.scss";
@import "fonts";
@import "config";
@import "layout";
@import "results-screen";

html, body, * {
  box-sizing: border-box;
}
html, body {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'arsmaquettepro', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  background: url(../assets/bg-tiles.png);
  //position: absolute;
  //left: 0;
  //top: 0;
  //bottom: 0;
  //right: 0;
  //overflow-x: hidden;
  //overflow-y: auto;


  &:before {
    content: '';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0.3;
    background-image: linear-gradient(270deg, rgba(97,97,97,0.90) 0%, rgba(255,255,255,0.09) 35%, rgba(255,255,255,0.00) 50%, rgba(255,255,255,0.09) 64%, rgba(97,97,97,0.90) 100%);
  }
}



.screen {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden auto;

  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;

  transition: transform 0.6s ease, opacity 0.3s;
  transform: translateX(-100vw);
  opacity: 0;

  &.active {
    transform: translateX(0) !important;
    opacity: 1;
  }
  &.upcoming {
    transform: translateX(100vw);
  }

  @media (max-width: 768px) {
    padding: 35px 15px;
  }

  @media (max-width: 700px) {
    padding: 30px 10px;
  }
}

h1 {
  font-size: 53px;
  color: $color-orange;
  letter-spacing: 0;
  text-align: center;
  font-family: 'arsmaquettepro', sans-serif;
  font-weight: 600;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 40px;
    margin-bottom: 18px;
  }
  @media (max-width: 500px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

h2 {
  font-size: 36px;
  color: #544D44;
  letter-spacing: 0;
  text-align: center;
  line-height: (40/36);
  font-weight: 600;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    font-size: 32px;
    margin-bottom: 50px;
  }
  @media (max-width: 500px) {
    font-size: 22px;
    margin-bottom: 30px;
  }
}

.eyebrow {
  font-size: 13px;
  color: #544D44;
  letter-spacing: 2.4px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 5px;
  }
  @media (max-width: 500px) {
    font-size: 11px;
    margin-bottom: 10px;
  }
}

p {
  font-size: 18px;
  max-width: 600px;
  color: #544D44;
  letter-spacing: 0;
  text-align: center;
  line-height: (24/18);
  margin-bottom: 40px;
}

.btn {
  transition: all 0.3s ease;
  background: #E84A32;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.10);
  border-radius: 8px;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 1em;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 14px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  font-weight: 600;

  &:hover {
    background: darken($color-orange, 15%);
  }

  &.btn-primary {
    background: $color-orange;
    box-shadow: 0 3px 3px 0 rgba(0,0,0,0.10);
    border-radius: 8px;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    //border: 2px solid #E84A32;
    height: 46px;
    padding-left: 24px;
    padding-right: 24px;

    &[disabled] {
      opacity: 0;
    }

    &:after {
      content: url('../assets/chevron-right.svg');
      margin-left: 10px;
      transform: translateY(2px);
    }

    &:hover {
      background: darken($color-orange, 15%);
    }
  }

  &.btn-download {
    background: $color-orange;
    box-shadow: 0 3px 3px 0 rgba(0,0,0,0.10);
    border-radius: 8px;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    //border: 2px solid #E84A32;
    height: 46px;
    padding-left: 24px;
    padding-right: 24px;

    &[disabled] {
      opacity: 0;
    }

    &:after {
      content: url('../assets/icon_download.svg');
      margin-left: 10px;
      transform: translateY(2px);
    }

    &:hover {
      background: darken($color-orange, 15%);
    }
  }


  &.btn-back {
    background: transparent; //lighten($color-text, 20%);
    box-shadow: none; // 0 3px 3px 0 rgba(0,0,0,0.10);
    border-radius: 8px;
    font-size: 16px;
    color: $color-text;
    letter-spacing: 0;
    //border: 2px solid #E84A32;
    height: 46px;
    padding-left: 24px;
    padding-right: 24px;

    &[disabled] {
      opacity: 0.2;
      pointer-events: none;
    }

    &:before {
      content: url('../assets/chevron-left.svg');
      margin-right: 10px;
      transform: translateY(2px);
    }

    &:hover {
      //background: rgba($color-text, 0.1);
      //color: $color-orange;
      text-decoration: underline;
    }
  }

  &.btn-next {
    background: $color-orange;
    box-shadow: 0 3px 3px 0 rgba(0,0,0,0.10);
    border-radius: 8px;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    //border: 2px solid #E84A32;
    height: 46px;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;

    &[disabled] {
      opacity: 0.2;
      pointer-events: none;
      transform: scale(0.8);

      &:before {
        animation: none;
      }
    }

    &:after {
      content: url('../assets/chevron-right.svg');
      margin-left: 10px;
      transform: translateY(2px);
    }

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      content: '';
      display: block;
      border-radius: 8px;

      background: $color-orange;
      z-index: -1;

      animation: btn-next-animation 2s infinite;
    }

    &:hover {
      background: darken($color-orange, 15%);
    }
  }

  &.btn-refresh {
    background: transparent;
    box-shadow: none;
    border-radius: 8px;
    font-size: 16px;
    color: $color-orange;
    letter-spacing: 0;
    //border: 2px solid #E84A32;
    height: 46px;
    padding-left: 24px;
    padding-right: 24px;

    &[disabled] {
      opacity: 0;
    }

    &:after {
      content: url('../assets/icon_refresh.svg');
      margin-left: 10px;
      transform: translateY(2px);
      transition: all 0.2s;
    }

    &:hover {
      //background: darken($color-orange, 15%);
      text-decoration: underline;


      &:after {

        transform: translateY(2px) rotate(90deg);
        transform-origin: 7px 8px;
      }
    }
  }

}


a {
  color: $color-orange;
  text-decoration: underline;
  transition: all 0.2s;
  &:hover {
    color: darken($color-orange, 15%);
  }
}

.opacityHidden {
  opacity: 0;
}

.uberHidden {
  opacity: 0;
  transition: all 0.5s ease-in !important;
}

.buttonsSet {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  button + button {
    margin-left: 20px;
  }

  &.canStack {
    @media (max-width: 500px) {
      flex-direction: column;
      .btn {
        justify-content: center;
        margin-left: 0 !important;
        margin-bottom: 15px;
      }
    }
  }
}

@keyframes btn-next-animation {
  0% {
    transform: scale(1);
    background: $color-orange;
  }
  95% {
    transform: scale(2);
    background: white;
    opacity: 0;
    border-radius: 16px;
  }
  100% {
    transform: scale(2);
    background: white;
    opacity: 0;
    border-radius: 16px;
  }
}


//web font include
@font-face {
  font-family: 'arsmaquettepro';
  src: url('../assets/fonts/arsmaquettepro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'arsmaquettepro';
  src: url('../assets/fonts/arsmaquettepro-medium-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'arsmaquettepro';
  src: url('../assets/fonts/arsmaquettepro-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'arsmaquettepro';
  src: url('../assets/fonts/arsmaquettepro-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

